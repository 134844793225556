import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Footer from '../components/Footer';
import Container from '../components/Container';
import Header from '../components/Header';
import BuildRelationshipsSection from '../components/sections/BuildRelationshipsSection';
import NaturesBakerySection from '../components/clients/NaturesBakerySection';
import UsWaterSystemsSection from '../components/clients/UsWaterSystemsSection';
import TopBrandsSection from '../components/sections/TopBrandsSection';

const ThankYouBecomeAPartnerPage = () => (
  <Layout>
    <Seo title="Thank You" />

    <Header graphic={undefined}>Thank You</Header>

    <section className="bg-white text-sp-dark">
      <Container className="my-24 ">
        <div className="prose prose-lg max-w-none prose-headings:font-normal prose-h2:text-4xl prose-h3:text-3xl text-lg">
          <p>Thank you for reaching out to the team at Subscribe Pro. We will get back to you soon!</p>{' '}
          <p>
            {' '}
            If your request is urgent, please give us a call at{' '}
            <a href="tel:8889183569" className="font-bold text-base">
              (888) 918-3569
            </a>{' '}
            or <Link href="/support">contact our support team</Link>.
          </p>
        </div>
      </Container>
    </section>

    <UsWaterSystemsSection />
    <NaturesBakerySection />

    <TopBrandsSection containerClassName="max-w-screen-lg" showOneLineOnly showCards={false} />

    <BuildRelationshipsSection />

    <Footer />
  </Layout>
);

export default ThankYouBecomeAPartnerPage;
